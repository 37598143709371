<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
  <div class="container-fluid mt-4">
    <b-alert :show="loading" variant="info">Loading...</b-alert>
    <b-row>
      <b-col>
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Username/Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Last Edit</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.userName }}</td>
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>
                {{ toLocaleDate(user.modificationDatetime) }} (User ID
                {{ user.modifiedByUser }})
              </td>
              <td class="text-right">
                <b-row>
                  <b-button
                    v-if="isUserAuthorized"
                    variant="outline-primary"
                    size="sm"
                    class="m-1"
                    @click.prevent="populateUserToEdit(user)"
                  >
                    <b-icon-pencil />
                  </b-button>
                  <b-button
                    v-if="isUserAuthorized"
                    variant="outline-primary"
                    size="sm"
                    class="my-1"
                    @click.prevent="deleteUser(user.id, user.userName)"
                  >
                    <b-icon-trash />
                  </b-button>
                </b-row>
              </td>
            </tr>
          </tbody>
        </table>
      </b-col>

      <b-modal
        id="modal-add-user"
        @ok="saveUser"
        ok-title="Save User"
        :title="model.id ? 'Edit User' : 'New User'"
      >
        <form @submit.prevent="saveUser">
          <b-form-group label="Email">
            <b-form-input type="email" v-model="model.email"></b-form-input>
          </b-form-group>
          <b-form-group label="Password">
            <b-row>
              <b-col>
                <b-form-input
                  :type="passwordFieldType"
                  v-on:click="showPasswordInstructions = true"
                  v-model="model.password"
                />
              </b-col>
              <b-col md="auto">
                <b-icon-eye
                  class="center"
                  @mouseover="passwordFieldType = 'text'"
                  @mouseleave="passwordFieldType = 'password'"
                />
              </b-col>
            </b-row>
            <div v-show="showPasswordInstructions" class="form-group">
              The new password will need:
              <ul>
                <li>At least eight characters</li>
                <li>At least one upper case English letter</li>
                <li>At least one lower case English letter</li>
                <li>At least one digit</li>
                <li>
                  At least one special character from this list: #?!@$%^&*-{}~
                </li>
              </ul>
            </div>
          </b-form-group>
          <b-form-group label="First Name">
            <b-form-input type="text" v-model="model.firstName"></b-form-input>
          </b-form-group>
          <b-form-group label="Last Name">
            <b-form-input type="text" v-model="model.lastName"></b-form-input>
          </b-form-group>
          <b-form-group label="Phone">
            <b-form-input type="number" v-model="model.phone"></b-form-input>
          </b-form-group>
        </form>
        <div class="form-group">
          <div v-if="errorMessage" class="alert alert-danger" role="alert">
            {{ errorMessage }}
          </div>
        </div>
      </b-modal>
    </b-row>

    <b-row>
      <b-col>
        <b-button
          v-if="isUserAuthorized"
          @click.prevent="openNewUserForm"
          size="sm"
          class="m-1"
          variant="primary"
        >
          <b-icon-plus font-scale="1.3"></b-icon-plus>Add User
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateToLocaleString } from "@/components/utils.js";
import ApiService from "@/services/api.service";

export default {
  data() {
    return {
      loading: false,
      users: [],
      model: {},
      showPasswordInstructions: false,
      errorMessage: "",
      passwordFieldType: "password",
    };
  },
  async created() {
    this.refreshUsers();
  },
  computed: {
    ...mapGetters("auth", [
      "isIntentoSales",
      "isCustomerUser",
      "getCustomerIdOfAccountManager",
    ]),
  },
  methods: {
    isUserAuthorized() {
      return (
        this.isIntentoSales ||
        (this.isCustomerUser &&
          this.customerIdOfAccountManager == this.$parent.customerId)
      );
    },
    async refreshUsers() {
      this.loading = true;
      this.users = await ApiService.getSiteUsers(this.$parent.siteId);
      this.loading = false;
    },
    async openNewUserForm() {
      this.model = {}; // reset form
      this.errorMessage = '';
      this.$bvModal.show("modal-add-user");
    },
    async populateUserToEdit(user) {
      this.model = Object.assign({}, user);
      this.errorMessage = '';
      this.$bvModal.show("modal-add-user");
    },
    async saveUser(event) {
      event.preventDefault();
      this.model.userName = this.model.email;
      this.model.type = "SiteUser";
      this.model.roles = ["SiteAdmin"];
      if (this.model.id) {
        await ApiService.updateUser(this.model.id, this.model).then(
          () => {
            this.model = {}; // reset form
            this.errorMessage = '';
            this.loading = false;
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-user");
            });
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        this.model.siteId = Number(this.$parent.siteId);
        await ApiService.addUser(this.model).then(
          () => {
            this.loading = false;
            this.model = {}; // reset form
            this.errorMessage = '';
            // Hide the modal manually
            this.$nextTick(() => {
              this.$bvModal.hide("modal-add-user");
            });
          },
          (error) => {
            this.loading = false;
            this.errorMessage =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
          }
        );
      }
      await this.refreshUsers();
    },
    async deleteUser(id, username) {
      if (confirm(`Are you sure you want to delete user ${username} ?`)) {
        // if we are editing a user we deleted, remove it from the form
        if (this.model.Id === id) {
          this.model = {};
        }
        await ApiService.deleteUser(id);
        await this.refreshUsers();
      }
    },
    toLocaleDate(d) {
      return formatDateToLocaleString(d);
    },
  },
};
</script>
